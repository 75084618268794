export const sendEmailTemplate = (name) => {
  return `<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"
      rel="stylesheet"
    />
    <title>Event Registration Confirmation</title>
  </head>
  <body
    style="
      margin: 0;
      padding: 0;
      font-family: Poppins, sans-serif;
      color: #333333;
      background-color: #f5f5f5;
    "
  >
    <table width="100%" cellpadding="0" cellspacing="0" border="0">
      <tr>
        <td align="center" style="padding: 20px 0">
          <table
            width="600"
            cellpadding="0"
            cellspacing="0"
            border="0"
            style="
              background-color: #ffffff;
              border-radius: 4px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
              max-width: 100%;
            "
          >
            <tr>
              <td style="padding: 0;">
                <table
                  width="100%"
                  cellpadding="0"
                  cellspacing="0"
                  border="0"
                  style="
                    background-color: #001a57;
                    background-image: url('https://res.cloudinary.com/analytics-intelligence/image/upload/v1742909055/e-bg_wlosz9.png');
                    background-size: cover;
                    background-position: center;
                  "
                >
                  <tr>
                    <td align="center" style="padding: 30px 20px;">
                      <img
                        src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1742988369/ai-logo_c7tccn.png"
                        alt="[AI] Logo"
                        style="max-width: 100%; height: auto; display: block;"
                        width="280"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style="padding: 30px 20px 20px 20px">
                <p style="margin: 0 0 15px 0; font-size: 16px">Dear ${name},</p>
                <p style="margin: 0 0 20px 0; font-size: 16px">
                  Thank you for registering for
                  <strong>AI Data Stack Connect 2025</strong>. We are excited to
                  have you join us for this exclusive event.
                </p>

                <p
                  style="
                    margin: 25px 0 15px 0;
                    font-size: 18px;
                    font-weight: bold;
                  "
                >
                  Event Details:
                </p>
                <table
                  width="100%"
                  cellpadding="0"
                  cellspacing="0"
                  border="0"
                  style="margin-bottom: 25px"
                >
                  <tr>
                    <td width="24" valign="top" style="padding: 4px 10px 4px 0;">
                      <img
                        width="24"
                        height="24"
                        src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1742989063/cal_qmvemn.png"
                        alt="calendar"
                        style="display: block;"
                      />
                    </td>
                    <td style="font-size: 16px; padding-bottom: 6px">
                      April 16, 2025
                    </td>
                  </tr>
                  <tr>
                    <td width="24" valign="top" style="padding: 4px 10px 4px 0;">
                      <img
                        width="24"
                        height="24"
                        src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1742989063/time_zttbce.png"
                        alt="time"
                        style="display: block;"
                      />
                    </td>
                    <td style="font-size: 16px; padding-bottom: 6px">
                      3:30 PM (UK Time)
                    </td>
                  </tr>
                  <tr>
                    <td width="24" valign="top" style="padding: 4px 10px 4px 0;">
                      <img
                        width="24"
                        height="24"
                        src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1742989063/map_t8pbeb.png"
                        alt="map"
                        style="display: block;"
                      />
                    </td>
                    <td style="font-size: 16px; padding-bottom: 6px">
                      Location: One Canada Square, London E14 5AB
                    </td>
                  </tr>
                </table>

                <p style="margin: 0 0 15px 0; font-size: 16px">
                  For more details, including the event agenda, please visit our
                  official event page:<br />
                  <a
                    href="https://analyticsintelligence.com/event/ai-data-stack-connect-2025"
                    style="color: #1a8aff; text-decoration: none"
                    >AI Data Stack Connect 2025</a
                  >
                </p>

                <p style="margin: 20px 0; font-size: 16px">
                  If you have any questions, feel free to contact us at<br />
                  <a
                    href="mailto:events@analyticsintelligence.com"
                    style="color: #333333; text-decoration: none"
                    >events@analyticsintelligence.com</a
                  >
                </p>
                
                <p style="margin: 20px 0; font-size: 16px">
                  We look forward to seeing you at
                  <strong>AI Data Stack Connect 2025</strong>!
                </p>

                <p style="margin: 30px 0 5px 0; font-size: 16px">Best regards,</p>
                <p style="margin: 0; font-size: 16px">Analytics Intelligence</p>
              </td>
            </tr>

            <tr>
              <td style="padding: 20px 0 0 0">
                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                  <tr>
                    <td style="height: 1px; background-color: #e5e5e5"></td>
                  </tr>
                  <tr>
                    <td
                      align="center"
                      style="padding: 20px; font-size: 12px; color: #333333"
                    >
                      Copyright 2025 [AI] Analytics Intelligence Inc. All Rights
                      Reserved
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        height: 1px;
                        background-color: #e5e5e5;
                        font-weight: 600;
                      "
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>
`;
};
