import { useForm } from "@formspree/react";
import CountdownTimer from "./component/countdown-timer";
import SpeakerCard from "./component/speaker-card";
import BulletPoint from "./component/bullet-point";
import AgendaItem from "./component/agenda-item";
import { Link } from "react-router-dom";
import { Logo } from "../../../assets";
import toast from "react-hot-toast";
import { useEffect, useRef } from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import { sendEmailTemplate } from "./sendEmailTemplate";
const helmetData = new HelmetData({});

export default function AIEvent() {
  const [state, handleSubmit, reset] = useForm("xnnpdlbl");

  const formRef = useRef(null);

  useEffect(() => {
    if (state.succeeded && formRef.current) {
      toast.dismiss();
      toast.success(
        "🎉 Your registration was successful! We'll contact you shortly.",
        {
          duration: 3000,
          position: "top-center",
          style: {
            background: "#2F4171",
            color: "#fff",
            fontWeight: "bold",
          },
        }
      );
      setTimeout(() => {
        if (formRef.current) {
          formRef.current.reset();
        }
        reset();
      }, 1000);

      const formData = new FormData(formRef.current);

      const email = formData.get("email");
      const firstName = formData.get("firstName");

      const data = new FormData();
      data.append("to", email);
      data.append("subject", "Event Registration Confirmation");
      data.append("body", sendEmailTemplate(firstName));
      data.append("messageType", "html");
      data.append("from", "no-reply");
      data.append("senderName", "Analytics Intelligence");

      fetch("https://email-service-tvisb.ondigitalocean.app/api/email/send", {
        method: "POST",
        body: data,
      });
    }
  }, [state.succeeded, reset]);

  const eventDate = new Date("April 16, 2025 15:30:00").getTime();

  const speakers = [
    {
      name: "David Edoja",
      role: "Founder CEO",
      company: "[AI] Analytics Intelligence",
      imageUrl:
        "https://res.cloudinary.com/analytics-intelligence/image/upload/v1742727529/_MG_0081_1_grd1ga.png",
    },
    {
      name: "Johnathan Conlon",
      role: "CRM & Analytics Manager",
      company: "Royal Mail",
      imageUrl:
        "https://res.cloudinary.com/analytics-intelligence/image/upload/v1742727528/johnathan.conlon.hs_41_1_h9utjb.png",
    },
    {
      name: "Charlie Kellaway",
      role: "Business Development Manager",
      company: "London Stock Exchange",
      imageUrl:
        "https://res.cloudinary.com/analytics-intelligence/image/upload/v1742727529/thumbnail_image001_1_fodfr0.png",
    },
  ];

  const whyAttendItems = [
    {
      title: "See AI in Action",
      description:
        "Explore how [AI]'s technology is transforming analytics through AI-driven data orchestration and automation.",
    },
    {
      title: "Customer Success Stories",
      description:
        "Hear directly from enterprises leveraging our platform to build and deploy AI applications.",
    },
    {
      title: "Industry Insights",
      description:
        "Gain expert perspectives from our partners and how their solutions are part of the AI data stack optimization.",
    },
    {
      title: "Networking Opportunities",
      description:
        "Connect with like-minded professionals, AI experts, and decision-makers shaping the future of data analytics.",
    },
  ];

  const whoShouldJoinItems = [
    {
      title: "Business Decision-Makers",
      description:
        "Discover how AI-powered insights can drive better business outcomes.",
    },
    {
      title: "Data & Analytics Leaders",
      description: "Understand how AI is changing enterprise analytics.",
    },
    {
      title: "AI & ML Practitioners",
      description: "Learn how AI-ready data stacks streamline deployment.",
    },
    {
      title: "Technical Teams",
      description:
        "Gain practical insights on building AI applications with enterprise data.",
    },
  ];

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence | Data Stack Connect 2025 | April 16
        </title>
        <link rel="canonical" href="/" />
        <meta
          name="description"
          content="[AI] Data Stack Connect is the premier event by Analytics Intelligence, bringing together industry leaders, data experts, and AI innovators to explore the future of AI-driven analytics. Discover how enterprises are building and deploying AI-ready applications, leveraging the power of the modern data stack to drive insights and automation."
        />
        <meta
          name="keywords"
          content="Analytics intelligence, Application and Language Layer, Analytics and Algorithm Layer, Data Warehouse and Compute Layer, leading companies, data-driven future, Analytics ready NLP systems, Large Language Models, Digital Analytics Stack, business questions, data-driven decision-making, data growth, information overload, middle layer analytics solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className="min-h-screen bg-[#fff]">
        <div className="text-white relative overflow-hidden md:px-[86px] px-[10px] bg-ai_event_hero_bg bg-cover">
          {/* Main content */}
          <div className="container mx-auto px-4 py-8 md:py-16 relative z-1">
            <div className="grid md:grid-cols-2 gap-8 items-start">
              {/* Left column - Event info */}
              <div>
                <Link reloadDocument to="/" className="mb-[37px] block">
                  <img src={Logo} alt="logo" className="h-[40px]" />
                </Link>
                <h1 className="header-main font-sora text-[35px] md:text-[48px] lg:text-[51px] font-bold leading-[120%]">
                  [AI] Data Stack <br /> Connect <br />{" "}
                  <span className="md:text-[28px] lg:text-[35px] leading-none">
                    From Data to Insight with AI Agents
                  </span>
                </h1>

                <div className="flex items-center mt-8 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M32.5 5H28.75V3.75C28.75 3.41848 28.6183 3.10054 28.3839 2.86612C28.1495 2.6317 27.8315 2.5 27.5 2.5C27.1685 2.5 26.8505 2.6317 26.6161 2.86612C26.3817 3.10054 26.25 3.41848 26.25 3.75V5H13.75V3.75C13.75 3.41848 13.6183 3.10054 13.3839 2.86612C13.1495 2.6317 12.8315 2.5 12.5 2.5C12.1685 2.5 11.8505 2.6317 11.6161 2.86612C11.3817 3.10054 11.25 3.41848 11.25 3.75V5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V32.5C5 33.163 5.26339 33.7989 5.73223 34.2678C6.20107 34.7366 6.83696 35 7.5 35H32.5C33.163 35 33.7989 34.7366 34.2678 34.2678C34.7366 33.7989 35 33.163 35 32.5V7.5C35 6.83696 34.7366 6.20107 34.2678 5.73223C33.7989 5.26339 33.163 5 32.5 5ZM11.25 7.5V8.75C11.25 9.08152 11.3817 9.39946 11.6161 9.63388C11.8505 9.8683 12.1685 10 12.5 10C12.8315 10 13.1495 9.8683 13.3839 9.63388C13.6183 9.39946 13.75 9.08152 13.75 8.75V7.5H26.25V8.75C26.25 9.08152 26.3817 9.39946 26.6161 9.63388C26.8505 9.8683 27.1685 10 27.5 10C27.8315 10 28.1495 9.8683 28.3839 9.63388C28.6183 9.39946 28.75 9.08152 28.75 8.75V7.5H32.5V12.5H7.5V7.5H11.25ZM32.5 32.5H7.5V15H32.5V32.5ZM26.5094 19.1156C26.6256 19.2317 26.7178 19.3696 26.7807 19.5213C26.8436 19.6731 26.876 19.8357 26.876 20C26.876 20.1643 26.8436 20.3269 26.7807 20.4787C26.7178 20.6304 26.6256 20.7683 26.5094 20.8844L19.0094 28.3844C18.8933 28.5006 18.7554 28.5928 18.6037 28.6557C18.4519 28.7186 18.2893 28.751 18.125 28.751C17.9607 28.751 17.7981 28.7186 17.6463 28.6557C17.4946 28.5928 17.3567 28.5006 17.2406 28.3844L13.4906 24.6344C13.2561 24.3998 13.1243 24.0817 13.1243 23.75C13.1243 23.4183 13.2561 23.1002 13.4906 22.8656C13.7252 22.6311 14.0433 22.4993 14.375 22.4993C14.7067 22.4993 15.0248 22.6311 15.2594 22.8656L18.125 25.7328L24.7406 19.1156C24.8567 18.9994 24.9946 18.9072 25.1463 18.8443C25.2981 18.7814 25.4607 18.749 25.625 18.749C25.7893 18.749 25.9519 18.7814 26.1037 18.8443C26.2554 18.9072 26.3933 18.9994 26.5094 19.1156Z"
                      fill="white"
                    />
                  </svg>
                  <span className="text-[20px] font-[600]">April 16, 2025</span>
                </div>

                <div className="flex items-center mb-10 gap-1">
                  <img
                    className="w-[40px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1742891270/location-2952_ocva5o.png"
                    alt="location"
                  />

                  <span className="text-[20px] font-[600]">
                    One Canada Square, London E14 5AB
                  </span>
                </div>

                {/* Countdown timer */}
                <CountdownTimer targetDate={eventDate} />
              </div>

              {/* Right column - Registration form */}
              <div className="ai-event-form p-6 md:p-8 mt-8 md:mt-0">
                <h2 className="font-sora text-2xl font-bold mb-2">Register</h2>
                <form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="space-y-4"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      required
                      className="w-full px-4 py-2 rounded bg-white text-black border border-blue-400/30 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      required
                      className="w-full px-4 py-2 rounded-[8px] bg-white text-black border border-blue-400/30 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>
                  <input
                    type="text"
                    name="company"
                    placeholder="Company"
                    required
                    className="w-full px-4 py-2 rounded-[8px] bg-white text-black border border-blue-400/30 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                  <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    required
                    className="w-full px-4 py-2 rounded-[8px] bg-white text-black border border-blue-400/30 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Work Email"
                    required
                    className="w-full px-4 py-2 rounded-[8px] bg-white text-black border border-blue-400/30 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone Number (Optional)"
                    className="w-full px-4 py-2 rounded-[8px] bg-white text-black border border-blue-400/30 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="w-full py-3 bg-blue-500 hover:bg-blue-600 transition-colors rounded-[100px] font-medium"
                  >
                    {state.submitting ? "Submitting..." : "Register"}
                  </button>
                </form>
              </div>
            </div>

            <div className="mt-8 text-sm md:text-base leading-relaxed">
              <p className="leading-[170%]">
                <strong>[AI] Data Stack Connect</strong> is the premier event by{" "}
                <strong>Analytics Intelligence</strong>, bringing together
                industry leaders, data experts, and AI innovators to explore the
                future of AI-driven analytics. Discover how enterprises are
                building and deploying AI-ready applications, leveraging the
                power of the modern data stack to drive insights and automation.
              </p>
            </div>
            {/* Speakers section */}
            <h2 className="font-sora text-3xl font-bold mt-16 mb-8 text-center">
              Speakers
            </h2>
            <div className="flex flex-wrap justify-center gap-8 gap-[74px] w-full">
              {speakers.map((speaker, index) => (
                <SpeakerCard
                  key={index}
                  name={speaker.name}
                  role={speaker.role}
                  company={speaker.company}
                  imageUrl={speaker.imageUrl}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="bg-blue-500 h-4"></div>
        <div className="sm:px-[80px] px-[12px] py-[34px]">
          <div className="ai-event-agenda flex flex-col gap-[50px] sm:p-[24px] p-[12px]">
            {/* Why Attend Section */}
            <div className="">
              <h2 className="font-sora text-3xl  font-bold mb-8 text-[#2F4171]">
                Why Attend?
              </h2>
              <div className="space-y-4">
                {whyAttendItems.map((item, index) => (
                  <BulletPoint
                    key={index}
                    title={item.title}
                    description={item.description}
                  />
                ))}
              </div>
            </div>

            {/* Who Should Join Section */}
            <div className="">
              <h2 className="font-sora text-3xl font-bold mb-8 text-[#2F4171]">
                Who Should Join?
              </h2>
              <div className="space-y-4">
                {whoShouldJoinItems.map((item, index) => (
                  <BulletPoint
                    key={index}
                    title={item.title}
                    description={item.description}
                  />
                ))}
              </div>
            </div>

            {/* Agenda Section */}
            <div className="">
              <h2 className="font-sora text-3xl font-bold mb-8">Agenda</h2>

              <div className="bg-white text-black overflow-hidden py-6 md:py-8">
                <AgendaItem
                  time="15:30 - 16:00"
                  title="Registration and Welcome"
                  items={[
                    "Guest arrivals and registration.",
                    "Light refreshments available.",
                  ]}
                />

                <AgendaItem
                  time="16:00 - 16:30:"
                  title="Keynote Speeches"
                  items={[
                    "Representative from [AI] to introduce the mission and vision behind the Data Assistant.",
                    "Speaker from ObservePoint to highlight the collaboration and benefits of their tools.",
                    "Speaker from Tealium to highlight the collaboration and benefits of their tools.",
                  ]}
                />

                <AgendaItem
                  time="16:30 - 17:30:"
                  title="Panel Discussion"
                  customContent={
                    <div>
                      <div className="mb-2 ml-4">
                        <div>
                          <span className="font-bold">Topic:</span> Real World
                          Applications of Data Analytics and GenAI Tools
                        </div>
                        <div>
                          <span className="font-bold">Moderator:</span> David
                          Edoja - [AI] Analytics Intelligence
                        </div>
                      </div>

                      <div className="mb-2 ml-4">
                        <div>
                          <span className="font-bold">Panelists:</span>
                        </div>
                        <div className="ml-4">
                          <div>
                            Johnathan Conlon - Representative from Royal Mail to
                            share a use case for data automation and insights.
                          </div>
                          <div>
                            Charles Kellaway - Representative from LSE to talk
                            about the transformative impact of [AI]'s Data
                            Language Model.
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />

                <AgendaItem
                  time="17:30 - 18:00:"
                  title="Networking and Refreshments"
                />
              </div>
            </div>
          </div>
          <div className="mt-[20px] md:mt-[40px]">
            <h2 className="font-sora text-3xl font-bold mb-8 text-center">
              Partners
            </h2>
            <div className="flex flex-wrap items-center justify-center gap-12 mb-12">
              <div className="w-48">
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1742814043/Tealium-logo-transparent_1_izturi.png"
                  alt="Tealium"
                  className="object-contain"
                />
              </div>
              <div className="w-48">
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1742814043/ObservePoint-Gray-Logo-1_1_hntud4.png"
                  alt="ObservePoint"
                  className="object-contain"
                />
              </div>
            </div>

            {/* Venue Section */}
            <h2 className="font-sora text-3xl font-bold mb-6 text-center">
              Venue
            </h2>
            <div className="flex justify-center items-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2 text-[#1C1C1C]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <span className="text-lg font-medium">
                One Canada Square, London E14 5AB
              </span>
            </div>
            <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">
              One Canada Square is a landmark hub for progressive global
              businesses. Situated at the heart of Canary Wharf everything you
              need is on your doorstep.
            </p>

            <div className="grid md:grid-cols-2 gap-6 max-w-4xl mx-auto">
              <div className="">
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1742814044/c243dcd2-c92e-4201-8e8e-1b2cb3758a49-0_1_roelt0.png"
                  alt="One Canada Square Building"
                  fill
                  className="object-cover"
                />
              </div>
              <a
                href="https://maps.app.goo.gl/KaK63G9DaKa3urgj9"
                target="_blank"
                className=""
                rel="noreferrer"
              >
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1742814044/Screenshot_2025-03-24_at_10.24.37_2_kuyvhk.png"
                  alt="Map of One Canada Square"
                  fill
                  className="object-cover"
                />
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
