const BulletPoint = ({ title, description }) => {
    return (
      <div className="flex items-start">
        <div className="mr-3 mt-1.5">
          <div className="w-1.5 h-1.5 rounded-full bg-blue-500"></div>
        </div>
        <div>
          <span className="font-bold">{title}</span> - {description}
        </div>
      </div>
    )
  }
  
  export default BulletPoint
  
  