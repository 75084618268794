const AgendaItem = ({ time, title, items = [], customContent }) => {
  return (
    <div className="mb-12 last:mb-0 relative">
      {/* Vertical blue line */}

      <div className="mb-2">
        <h3 className="text-lg font-medium text-[#2F4171] font-[600]">
          {time} {title}
        </h3>
      </div>
      <div className="border-l border-l-[#1A8AFF] border-l-[2px] pl-[45px] ml-[21px] text-[#1C1C1C] font-[400]">
        {items.length > 0 && (
          <ul className="space-y-1 text-gray-700">
            {items.map((item, index) => (
              <li key={index} className="flex items-start">
                <span className="mr-2">•</span>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        )}
        {customContent && customContent}
      </div>
    </div>
  );
};

export default AgendaItem;
