import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/footer";
import HeaderNav from "./components/header";
import Main from "./components/main";
import About from "./components/main/About";
import Privacy from "./components/privacy";
import PLLM from "./components/main/pllm";
import Form from "./components/main/demoForm";
import { useScrollToTop } from "./components/main/useScrolltoTop";
import Retail from "./components/main/retail";
import Marketing from "./components/main/marketing";
import Fintech from "./components/main/fintech";
import Blogs from "./components/main/blog/blogs";
import SingleBlog from "./components/main/blog/singleBlog";
import Events from "./components/main/events/events";
import AiPlatform from "./components/main/AiPlatform";
import DataAssistant from "./components/main/DataAssistant";
import Consultation from "./components/main/Consultation";
import Training from "./components/main/Training";
import { Toaster } from "react-hot-toast";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Chatbot from "./components/chatBot";
import AIEvent from "./components/main/ai-event";

function App() {
  useScrollToTop();
  const location = useLocation(); // Get the current path

  return (
    <main className="App relative abstract-lines">
      {location.pathname !== "/event/ai-data-stack-connect-2025" && <HeaderNav />}
      
      <Toaster
        toastOptions={{
          style: {
            maxWidth: "700px",
            padding: "12px 16px",
            fontSize: "17px",
            fontWeight: "400",
          },
          error: {
            style: {
              color: "red",
            },
          },
          success: {
            style: {
              color: "green",
            },
          },
        }}
        position="top-center"
        reverseOrder={false}
      />
      
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/ai-platform" element={<AiPlatform />} />
        <Route exact path="/data-assistant" element={<DataAssistant />} />
        <Route exact path="/event/ai-data-stack-connect-2025" element={<AIEvent />} />
        <Route exact path="/retail" element={<Retail />} />
        <Route exact path="/marketing" element={<Marketing />} />
        <Route exact path="/fintech" element={<Fintech />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/blog" element={<Blogs />} />
        <Route exact path="/blog/:id" element={<SingleBlog />} />
        <Route exact path="/events" element={<Events />} />
        <Route exact path="/demo" element={<Form />} />
        <Route exact path="/pllm" element={<PLLM />} />
        <Route exact path="/company" element={<About />} />
        <Route
          exact
          path="/digital-analytics-support"
          element={<Consultation />}
        />
        <Route
          exact
          path="/digital-analytics-training"
          element={<Training />}
        />
        <Route path="*" element={<Navigate reloadDocument to="/" replace />} />
      </Routes>
      
      <Footer />
      <Chatbot />

      <style jsx="true">
        {`
          .Toastify__toast-container {
            z-index: 9999999;
          }
        `}
      </style>
    </main>
  );
}

export default App;
