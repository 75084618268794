import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function ScrollingBanner() {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    if (!container || !content) return;

    const clone = content.cloneNode(true);
    container.appendChild(clone);
    const contentWidth = content.offsetWidth;
    const duration = contentWidth / 20;

    container.style.animationDuration = `${duration}s`;

    container.classList.add("animate-scroll");
  }, []);

  return (
    <div className="container w-full overflow-hidden rounded-[100px] border border-[#525252] z-[1000]">
      <div
        ref={containerRef}
        className="whitespace-nowrap inline-block"
        style={{
          animation: "scroll linear infinite",
        }}
      >
        <div ref={contentRef} className="inline-block py-3 px-6  text-white">
          AI & Data Stack Connect 2025 - Explore the Future of AI & Data
          Innovation!
          <Link
            to="/event/ai-data-stack-connect-2025"
            className="text-[#0068E5] mx-2 font-semibold"
          >
            Register Now!
          </Link>
        </div>
        <div ref={contentRef} className="inline-block py-3 px-6  text-white">
          AI & Data Stack Connect 2025 - Explore the Future of AI & Data
          Innovation!
          <Link
            to="/event/ai-data-stack-connect-2025"
            className="text-[#0068E5]  mx-2 font-semibold"
          >
            Register Now!
          </Link>
        </div>
      </div>

      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        .animate-scroll {
          animation: scroll linear infinite;
        }
      `}</style>
    </div>
  );
}
