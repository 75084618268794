
import { useState, useEffect } from "react"

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = targetDate - new Date().getTime()

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24))
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
        const minutes = Math.floor((difference / 1000 / 60) % 60)
        const seconds = Math.floor((difference / 1000) % 60)

        setTimeLeft({ days, hours, minutes, seconds })
      }
    }

    calculateTimeLeft()
    const timer = setInterval(calculateTimeLeft, 1000)

    return () => clearInterval(timer)
  }, [targetDate])

  const formatNumber = (num) => {
    return num < 10 ? `0${num}` : num.toString()
  }

  return (
    <div className="flex flex-wrap justify-start sm:gap-4 gap-1">
      <div className="flex items-center">
        <div className="counter-card bg-blue-900/50 backdrop-blur-sm sm:p-4 p-2 rounded-lg w-20 h-20 flex flex-col items-center justify-center">
          <span className="text-2xl md:text-3xl font-bold font-sora">{formatNumber(timeLeft.days)}</span>
          <span className="text-xs text-gray-300 font-sora">Days</span>
        </div>
        <span className="text-2xl mx-2 font-light">:</span>
      </div>

      <div className="flex items-center">
        <div className="counter-card bg-blue-900/50 backdrop-blur-sm p-4 rounded-lg w-20 h-20 flex flex-col items-center justify-center">
          <span className="text-2xl md:text-3xl font-bold font-sora">{formatNumber(timeLeft.hours)}</span>
          <span className="text-xs text-gray-300 font-sora">Hours</span>
        </div>
        <span className="text-2xl mx-2 font-light">:</span>
      </div>

      <div className="flex items-center">
        <div className="counter-card bg-blue-900/50 backdrop-blur-sm p-4 rounded-lg w-20 h-20 flex flex-col items-center justify-center">
          <span className="text-2xl md:text-3xl font-bold font-sora">{formatNumber(timeLeft.minutes)}</span>
          <span className="text-xs text-gray-300 font-sora">Minutes</span>
        </div>
        <span className="text-2xl mx-2 font-light">:</span>
      </div>

      <div className="flex items-center">
        <div className="counter-card bg-blue-900/50 backdrop-blur-sm p-4 rounded-lg w-20 h-20 flex flex-col items-center justify-center">
          <span className="text-2xl md:text-3xl font-bold font-sora">{formatNumber(timeLeft.seconds)}</span>
          <span className="text-xs text-gray-300 font-sora">Seconds</span>
        </div>
      </div>
    </div>
  )
}

export default CountdownTimer

