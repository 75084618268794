
const SpeakerCard = ({ name, role, company, imageUrl = "/placeholder.svg?height=100&width=100" }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-20 h-20 md:w-24 md:h-24 rounded-full bg-white overflow-hidden mb-3 relative">
        <img src={imageUrl || "/placeholder.svg"} alt={name} fill className="object-cover" />
      </div>
      <h3 className="text-sm font-medium text-center">{name}</h3>
      <p className="text-sm text-[#D0D0D0] text-center">{role}</p>
      {company && <p className="text-sm text-[#D0D0D0] text-center">{company}</p>}
    </div>
  )
}

export default SpeakerCard

